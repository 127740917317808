import React from "react";

import { Grid, Cell } from "baseui/layout-grid";
import {
  DisplayMedium,
  MonoLabelMedium,
  LabelLarge,
  ParagraphMedium,
  DisplayLarge,
  MonoDisplayXSmall,
  LabelSmall,
} from "baseui/typography";
import { Button } from "baseui/button";
import { Tag } from "baseui/tag";
import { Accordion, Panel } from "baseui/accordion";
import { styled, useStyletron } from "baseui";
import { Check, Plus, ShoppingCart } from "tabler-icons-react";

import illustration from "../assets/illustration.svg";
import preview from "../assets/preview.mp4";
import { StyledLink } from "baseui/link";

const Hero = styled("section", ({ $theme }) => ({
  paddingTop: $theme.sizing.scale4800,
  paddingBottom: $theme.sizing.scale4800,
  background:
    "linear-gradient(180deg, rgba(212,218,235,0.4) 0%, rgba(255,255,255,0) 100%)",
}));

const Features = styled("section", ({ $theme }) => ({
  paddingTop: $theme.sizing.scale2400,
  paddingBottom: $theme.sizing.scale2400,
  ":after": {
    content: "a",
    bottom: 0,

    height: "100px",
    backgroundColor: $theme.colors.accent,
    width: "50%",
  },
}));

const Pricing = styled("section", ({ $theme }) => ({
  paddingTop: $theme.sizing.scale2400,
  paddingBottom: $theme.sizing.scale2400,
  backgroundColor: $theme.colors.backgroundSecondary,
}));

const Faq = styled("section", ({ $theme }) => ({
  paddingTop: $theme.sizing.scale2400,
  paddingBottom: $theme.sizing.scale2400,
}));

const FeaturesList = styled("div", () => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  height: "100%",
}));

const FeaturesListItem = ({ children }: { children: React.ReactNode }) => {
  const [css, theme] = useStyletron();
  return (
    <div
      className={css({
        display: "flex",
        alignItems: "center",
      })}
    >
      <div
        className={css({
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "4px",
          height: "20px",
          width: "20px",
          backgroundColor: theme.colors.positive50,
          color: theme.colors.positive,
        })}
      >
        <Check />
      </div>
      <ParagraphMedium
        $style={{
          marginLeft: "10px",
          marginBottom: 0,
          marginTop: 0,
        }}
      >
        {children}
      </ParagraphMedium>
    </div>
  );
};

export default function Index() {
  const [css, theme] = useStyletron();

  return (
    <>
      <Hero>
        <Grid>
          <Cell span={6}>
            <MonoLabelMedium
              $style={{
                fontWeight: 600,
                color: theme.colors.contentInverseSecondary,
              }}
            >
              A Trello Power Up for You & Your Teams
            </MonoLabelMedium>
            <DisplayLarge $style={{ fontWeight: 300, fontSize: "72px" }}>
              Export your cards
              <br />
              in blazing fast! ⚡
            </DisplayLarge>

            <Button
              $as={"a"}
              href={process.env.NEXT_PUBLIC_ADD_TO_TRELLO_URL}
              target="_blank"
              $style={{
                marginTop: theme.sizing.scale1000,
                backgroundColor: theme.colors.accent,
              }}
              endEnhancer={<Plus />}
            >
              Add to Trello
            </Button>
          </Cell>
          <Cell span={6}>
            <img src={illustration} width="100%" />
          </Cell>
        </Grid>
      </Hero>
      <Features id="features">
        <Grid>
          <Cell skip={[0, 1, 3]} span={6}>
            <div
              className={css({
                textAlign: "center",
                paddingBottom: theme.sizing.scale1600,
              })}
            >
              <DisplayMedium $style={{ fontWeight: 300 }}>
                Features
              </DisplayMedium>
            </div>
          </Cell>
        </Grid>
        <Grid>
          <Cell skip={[0, 0, 1]} span={[4, 4, 6]}>
            <video
              src={preview}
              autoPlay
              className={css({
                width: "100%",
                borderRadius: "4px",
                boxShadow: theme.lighting.shadow700,
              })}
              loop
            />
          </Cell>
          <Cell span={[4, 4, 4]}>
            <FeaturesList>
              <FeaturesListItem>Unlimited exports</FeaturesListItem>
              <FeaturesListItem>Unlimited boards</FeaturesListItem>
              <FeaturesListItem>Unlimited cards export</FeaturesListItem>
              <FeaturesListItem>Export to Excel, CSV or JSON</FeaturesListItem>
              <FeaturesListItem>
                Choose and arrange exported fields (including card ID, name,
                description, labels, members, attachments' names with links and
                more)
              </FeaturesListItem>
              <FeaturesListItem>Support for custom fields</FeaturesListItem>
              <FeaturesListItem>
                Set advanced filters to decide which cards are exported
              </FeaturesListItem>
              <FeaturesListItem>
                Split cards into Excel sheets by a member or a list
              </FeaturesListItem>
              <FeaturesListItem>
                Auto-apply the last used fitlers
              </FeaturesListItem>
              <FeaturesListItem>
                Export full data (items, due dates, assigned members, status) of
                Trello Checklists
              </FeaturesListItem>
            </FeaturesList>
          </Cell>
        </Grid>
      </Features>
      <Features>
        <Grid>
          <Cell skip={[0, 1, 3]} span={6}>
            <div
              className={css({
                textAlign: "center",
                paddingBottom: theme.sizing.scale1600,
              })}
            >
              <DisplayMedium $style={{ fontWeight: 300 }}>
                Video tutorial
              </DisplayMedium>
            </div>
          </Cell>
          <Cell skip={[0, 1, 3]} span={6}>
            <iframe
              width="100%"
              height="360"
              src="https://www.youtube.com/embed/t8ea--xVUFM"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Cell>
        </Grid>
      </Features>
      <Pricing id="pricing">
        <Grid>
          <Cell skip={[0, 1, 3]} span={6}>
            <div
              className={css({
                textAlign: "center",
                paddingBottom: theme.sizing.scale1600,
              })}
            >
              <DisplayMedium $style={{ fontWeight: 300 }}>
                Pricing
              </DisplayMedium>
            </div>
          </Cell>
          <Cell skip={[0, 1, 3]} span={6}>
            <div
              className={css({
                paddingTop: theme.sizing.scale1000,
                paddingRight: theme.sizing.scale1000,
                paddingBottom: theme.sizing.scale1000,
                paddingLeft: theme.sizing.scale1000,
                backgroundColor: theme.colors.backgroundPrimary,
                boxShadow: theme.lighting.shadow400,
                borderRadius: theme.borders.radius200,
                textAlign: "center",
              })}
            >
              <div
                className={css({
                  display: "flex",
                  justifyContent: "space-evenly",
                })}
              >
                <div>
                  <MonoDisplayXSmall>Monthly</MonoDisplayXSmall>
                  <Tag
                    closeable={false}
                    variant="solid"
                    kind="custom"
                    color="#aaffec"
                    overrides={{ Text: { style: { color: "#000" } } }}
                  >
                    popular
                  </Tag>
                  <LabelLarge $style={{ color: theme.colors.contentTertiary }}>
                    $6/month
                  </LabelLarge>
                </div>
                <div>
                  <MonoDisplayXSmall>Annual</MonoDisplayXSmall>
                  <Tag closeable={false} variant="solid" kind="purple">
                    75% off
                  </Tag>
                  <LabelLarge $style={{ color: theme.colors.contentTertiary }}>
                    $18/year (only $1.50/month)
                  </LabelLarge>
                </div>
              </div>

              <Button
                $style={{
                  marginTop: theme.sizing.scale1000,
                  marginBottom: theme.sizing.scale600,
                  backgroundColor: theme.colors.accent,
                }}
                $as="a"
                href={`${process.env.NEXT_PUBLIC_LICENSE_MANAGER_URL}/checkout`}
                endEnhancer={<ShoppingCart />}
              >
                Buy now
              </Button>

              <LabelSmall>Prices are per user for unlimited boards</LabelSmall>
            </div>
          </Cell>
        </Grid>
      </Pricing>
      <Faq id="faq">
        <Grid>
          <Cell skip={[0, 1, 3]} span={6}>
            <div
              className={css({
                textAlign: "center",
                paddingBottom: theme.sizing.scale1600,
              })}
            >
              <DisplayMedium $style={{ fontWeight: 300 }}>FAQ</DisplayMedium>
            </div>
          </Cell>
          <Cell skip={[0, 0, 2]} span={8}>
            <Accordion>
              <Panel title="Where can I use Smart Export?">
                Smart Export is a Power-Up for{" "}
                <StyledLink href="https://trello.com" target="_blank">
                  Trello
                </StyledLink>{" "}
                and you can use it only there.
              </Panel>
              <Panel title="How will the app be delivered to me?">
                The app has to be installed via{" "}
                <StyledLink href="https://trello.com/power-ups" target="_blank">
                  Trello Power-Ups marketplace
                </StyledLink>{" "}
                and will be working after entering a valid license key.
              </Panel>
              <Panel title="Is there any trial plan available?">
                No, currently Smart Export is offered only in paid plans.
              </Panel>
              <Panel title="How can I buy a license?">
                You can buy a license by using our{" "}
                <StyledLink
                  href={process.env.NEXT_PUBLIC_LICENSE_MANAGER_URL}
                  target="_blank"
                >
                  License Manager
                </StyledLink>
                . If you are a new user you can use the checkout form{" "}
                <StyledLink
                  href={`${process.env.NEXT_PUBLIC_LICENSE_MANAGER_URL}/checkout`}
                  target="_blank"
                >
                  here
                </StyledLink>
                . If you have bought licenses before,{" "}
                <StyledLink
                  href={`${process.env.NEXT_PUBLIC_LICENSE_MANAGER_URL}/login`}
                  target="_blank"
                >
                  log in
                </StyledLink>{" "}
                into your account and click “Buy Now” button on the dashboard.
              </Panel>
              <Panel title="How are recurring payments processed?">
                We use Paddle as an online payment service provider which it’s
                responsible for charging you monthly or annually.
              </Panel>
              <Panel title="How can I manage my account and my licenses?">
                Your account can be managed in our{" "}
                <StyledLink
                  href={process.env.NEXT_PUBLIC_LICENSE_MANAGER_URL}
                  target="_blank"
                >
                  License Manager
                </StyledLink>
                , where you can see your licenses and payments.
              </Panel>
              <Panel title="Can a license be transferred to another Trello member?">
                Yes, to do so you’ll need to unlink it and send a license key to
                the desired person.
              </Panel>
              <Panel title="How long is a license valid?">
                License is valid, depending on chosen plan, for a month or a
                year from successful payment.
              </Panel>
              <Panel title="Which payment methods do you accept?">
                We accept Visa and Mastercard debit and credit cards, Apple Pay,
                Google Pay and PayPal.
              </Panel>
            </Accordion>
          </Cell>
        </Grid>
      </Faq>
    </>
  );
}
